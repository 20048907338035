

@media print {
    .printDiv {
        border: 1px solid #959494;
        padding: 30px;
        border-radius: 10px;
    }
    .printDiv span {
        margin-left: 20px;
    }
}