@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,300&display=swap");

.App {
  text-align: center;
  font-family: "Roboto", serif;
  letter-spacing: 0.1rem;
}

.container {
  display: flex;
  flex-direction: row;
  margin: 5% auto;
  width: 635px;
  height: 430px;
  background: white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);
}

.hero_container {
  flex-direction: row;
  margin: 5% auto;
}

.item {
  width: 50%;
  position: relative;
}

.item-image {
  height: 430px;
  width: 100%;
  object-fit: cover;
}

.overlay-effect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  background-color: #303030;
  overflow: hidden;
  z-index: 1;
}

.item-details {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  margin-left: 20px;
  color: #84a17d;
  text-align: left;
}

.item-details__title {
  font-size: 22px;
}

.item-details__amount {
  font-weight: bolder;
}

.checkout {
  background: #84a17d; /* fallback for old browsers */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 430px;
  width: 50%;
}

.checkout-form {
  padding: 20px 20px;
}

.checkout-field {
  display: flex;
  flex-direction: column;
}

.checkout-field label {
  text-align: left;
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.checkout-field input {
  border: 1px solid #cecece;
  border-radius: 5px;
  color: #292929;
  padding-left: 10px;
}

.checkout-field input:focus {
  background-color: white;
}

.checkout-field input:focus {
  background-color: transparent;
  outline: none;
}

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.paystack-button:hover {
  background-color: darkblue;
}

.paystack-button:focus {
  outline: none;
}

.authForm {
  border: 1px solid #e3e3e3;
  padding: 30px;
  border-radius: 10px;
  background: rgba(220, 220, 220, 0.5);
}

.ant-spin-dot-item {
  background-color: white !important;
}
