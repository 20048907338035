@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;400;500;600;700;800&family=Nunito:ital,wght@0,400;0,500;0,600;0,700;1,500&family=Poppins:wght@100;200;300;400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  overflow-x: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px 2.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-accent {
  --tw-bg-opacity: 1;
  background-color: rgb(9 26 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 254 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.session-header {
  @apply font-semibold md:text-[15px] text-[12px];
}

.title {
  @apply font-semibold md:text-[20px] text-[15px];
}

.h1 {
  @apply md:text-[30px] text-[20px] font-normal md:font-semibold;
}

.h3 {
  @apply font-light md:text-[15px] text-[12px];
}
